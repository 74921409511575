import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function MetaDecorator({ title, description, page, keyword, questions = '' }) {
  var structuredDataQuestions = []

  if (questions.length > 0) {
    questions.map(({ title, content }) => (
      structuredDataQuestions.push(
        {
          "@type": "Question",
          "name": title,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>" + content + "</p>"
          }
        }
      )
    ))
  }
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`Photos | Rugby For Good`}</title>
        <meta name='description' content={`Photos | Rugby For Good`} />
        <meta name="keywords" content={`Photos | Rugby For Good`} />
        <link rel="canonical" href={``} />
       
       
      </Helmet>
    </HelmetProvider>
  )
}
