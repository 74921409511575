

import {
  BriefcaseIcon,
  ArrowLeftIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid'

export const formQuestions = [
  {
    htmlTag: "input",
    rows: "",
    type: "text",
    placeholder: "稱呼 Name",
    name: "form-name",
    validations: { required: true },
    errorMessages: { required: "請填寫此欄" }
  },
  {
    htmlTag: "input",
    rows: "",
    type: "tel",
    placeholder: "聯絡電話 Phone Number",
    name: "form-phone",
    validations: { required: true, pattern: /[4-6|9][0-9]{7}/ },
    errorMessages: { required: "請填寫此欄", pattern: "請輸入正確的電話號碼 (只需8個數字)" },
  },
  {
    htmlTag: "textarea",
    rows: "6",
    type: "message",
    placeholder: "留言 Your Message",
    name: "form-message",
    validations: { required: true, maxLength: 200 },
    errorMessages: { required: "請填寫此欄", maxLength: "字數上限為200字" },
  }
]

export const commonInfo =
{
  phone: "85264819288",
  phone2: "85297349700",
  email: "zinalogistics@gmail.com"
}

export const commonServices = [
  {
    alias: 'web-development',
    name: '網頁設計',
    abstract: '製定合適的網頁設計方案',
    description: '為您度身製作網站，製定合適的網頁設計方案，重視使用者體驗及視覺設計。無論是公司網頁、網上商店、個人化網頁等，都會針對客戶要求設計。',
    href: '/service/web-design',
    icon: PhoneIcon,
  },
  {
    alias: 'seo',
    name: 'SEO優化',
    abstract: '提昇網站SEO排名',
    description: '幫助提昇網站SEO排名，了解搜尋引擎的自然排名運作原理來優化網站。有效地改善您的網站以獲得更多的瀏覽量，產生更多流量，接觸更多潛在客戶。',
    href: '/service/seo',
    icon: PhoneIcon,
  },
  {
    alias: 'digital-marketing',
    name: '數碼營銷',
    abstract: "一站式全面數碼行銷方案",
    description: "一站式全面數碼行銷方案，為各行各業的客戶策劃不同類型的營銷計劃如搜尋引擎推廣(Google Ads)及內容行銷(Content Marketing)等，提升網站曝光率。",
    href: '/service/digital-marketing',
    icon: PhoneIcon
  },
]

export const commonContact = [
  { name: '聯絡客務：' + commonInfo.phone, href: '#', icon: PhoneIcon },
  { name: '電郵：' + commonInfo.email, href: '#', icon: PhoneIcon },
]

export const seoQuestionArray = [
  {
    title: "SEO是甚麼？",
    content: "SEO是透過了解搜尋引擎的運作原理後，對網站作出內容和程式碼上的調整，以提升網站的搜尋排名，增加曝光度。"

  },
  {
    title: "SEO和SEM有什麼差別？",
    content: "SEM優點是投入多少費用，就能立即取得對應的流量，但一旦停止投入金錢，便沒有功效；相反，SEO能夠相對性地長期保持網站的搜尋排名。"
  },
  {
    title: "沒有網站的話，但想發展網上生意，有其他服務可以幫助得到嗎？",
    content: "沒有問題，我們亦有數碼營銷計劃，並有顧問會針對您的需求與預算為您規劃合適的方案。例如您可以使用Facebook或Instagram發展網上生意，再交由我們為您發掘潛在用戶。如果你認為社交平台並不是合適的途徑，可參考我們的網頁開發計劃，為您量身訂造整個網站，更有效地發展您的網上生意。"
  },
];
export const webdevelopmentQuestionArray = [
  {
    title: "一站式服務提供甚麼？",
    content: "從程式碼開發、圖像設計，至網域及伺服器申請，我們一手包辦。 閣下可參與網站的用能及頁面設計部份，打造自己心意而獨一無二的理想網站，其他技術支援都可放心交給我們。我們更有專業且完善的SEO優化服務"

  },
  {
    title: "為何要申請網域名稱？",
    content: "網域名稱是屬於網站的獨有名稱，令客戶對你的品牌有一個亮眼的記憶點，往往一個有意義的網域名稱更能反映該網站的品牌理念。因此根據不同的網域名稱，所需的費用都有不同。"
  },
  {
    title: "我已經有自己的網站程式碼，可以幫我架設嗎？",
    content: "可以的，我們可以代為管理網站運作，詳情可向我們查詢。"
  },
];
export const designQuestionArray = [
  {
    title: "我可以購買單一項目？",
    content: "可以，我們以上提及的項目都能夠單一購買。如 閣下有其他設計項目，歡迎查詢。"

  }
];
export const digitalMarketingQuestionArray = [
  {
    title: "數碼營銷是甚麼？",
    content: "數碼營銷是指針對使用數碼裝置的用戶作出行銷。透過數位渠道對消費者推廣產品和服務，不需要有實體上的互動都能有效推銷及吸引用戶。"
  },
  {
    title: "數碼營銷有甚麼分類？",
    content: "數碼營銷種類繁多，包括社交媒體營銷、搜尋引擎行銷(SEM)、搜尋引擎優化(SEO)、電子郵件營銷等。由於太多分類，以上3個計劃中未有涵蓋所有數碼營銷手法， 閣下有相關需要都可以免費向我們查詢及索取報價。"
  },
];


export const DigitalMarketingSwiperList = [
  {
    arrayKey: 1,
    thumbnail: '',
    topHeading: "陳先生，職業 銀行經理 (15年)，每月收入約$110,000。陳先生目前欠款共250萬，包括信用卡債務和個人貸款。由於陳先生的工作穩定且有高收入，他曾多次嘗試申請貸款來償還債務，但因為過去的信用紀錄不佳而被各貸款機構拒絕。陳先生感到非常煩惱，急需找到解決債務問題的方法。",
    bottomHeading: "後來，陳先生得知了ThaiEasyHK，並尋求我們的協助來處理他的債務。我們通過大額清償和集中清償的方式，成功減輕了陳先生的供款壓力。由於他的工作並未受到影響，他可以繼續保持穩定的收入來還款。透過我們的幫助，陳先生成功解決了債務問題，並重新建立了良好的信用紀錄。"

  },
  {
    arrayKey: 2,
    thumbnail: '',
    topHeading: "蔡先生，每月收入約$35,000。蔡先生目前欠款共99萬，包括信用卡債務、銀行貸款和二線財務。由於蔡先生的工作需要與客戶長期應酬，且經常使用信用卡付款，因此累積了大量高息的信用卡債務。他曾多次嘗試自行償還債務，包括借貸款來還清信用卡債務，但未能成功。",
    bottomHeading: "後來，蔡先生尋求了ThaiEasyHK的二線清數服務。在短短十數天的時間內，我們成功為蔡先生處理了他的申請。透過我們的專業服務，蔡先生成功解決了他的債務問題，並得到了一個更可負擔的償還計劃。現在，他可以重建自己的財務狀況，並更有效地管理自己的財務。"
  },
  {
    arrayKey: 3,
    thumbnail: '',
    topHeading: "李小姐是一名專業會計師，每月收入8萬港幣。她的信用紀錄顯示總外債超過150萬港幣，每月供款高達6-7萬港幣。此外，她還需要負擔一家四口的生活開支，因此生活壓力非常大，每天都無法入睡，擔心債務問題。",
    bottomHeading: "透過我們的支援，李小姐可以獲得更具彈性的償還計劃，減輕她的負擔，並提供她所需的資金來應對生活開支。這使得她能夠放心地專注於工作，同時擁有更好的睡眠品質。我們的目標是幫助客戶度過困難時期，重新建立穩定的財務狀況。"
  }


];

export const MetaDataList = {
  home: {
    page: 'home',
    title: '私人貸款免TU 香港清數公司 - 專業貸款團隊',
    description: "ThaiEasyHK 泰易清成立於2012年是一間擁有超過10年以上經驗的專業貸款團隊，職員全部曾任職銀行及財務行業。過往成功處理數千宗減輕供款個案，幫助不同行業，不同財務問題的客人順利減輕供款壓力，減少客人多次申請，多次遞交文件仍然不成功的申請問題。",
    keyword: "二線清數,二線攬數,大額清數,財務清數,破產,按揭,借貸,清數易批,私人貸款免tu,清數專家"
  },
  contact: {
    page: 'contact',
    title: '聯絡我們',
    description: "ThaiEasyHK 泰易清成立於2012年是一間擁有超過10年以上經驗的專業貸款團隊，職員全部曾任職銀行及財務行業。過往成功處理數千宗減輕供款個案，幫助不同行業，不同財務問題的客人順利減輕供款壓力，減少客人多次申請，多次遞交文件仍然不成功的申請問題。",
    keyword: "二線清數,二線攬數,大額清數,財務清數,破產,按揭,借貸,清數易批,私人貸款免tu,清數專家"
  },
  error: {
    title: '頁面不存在',
    description: "這是404頁面。 您已被重定向至此處，因為您請求的頁面不存在！"
  }
}
