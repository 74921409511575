import React, { useState, useEffect } from 'react'
import Slider from '../components/Carousel/Slider'
import Form from '../components/Form'
import SocialMediaShare from '../components/SocialMediaShare'
import Swiper from '../components/Swiper'
import { DigitalMarketingSwiperList, MetaDataList } from '../components/Data'
import MetaDecorator from '../components/MetaDecorator'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid'
import {
    FacebookIcon,
} from "react-share";
import logo from '../images/logo.png'
import { getAlbums, getEvent } from "../services"
import ReactPaginate from 'react-paginate';

export default function Home() {
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(12);
    const [albums, setAlbums] = useState([])
    const [albumAias, setAlbumAlias] = useState('')
    const [photos, setPhotos] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [slide, setSlide] = useState([])
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        getAlbums(albumAias, currentPage).then((result) => {
            if (albumAias == '') {
                setAlbums(result) //for first enter page
                result = result.slice(0,1)
            }
            setPhotos(result)
            setPageCount(Math.ceil(result.reduce((count, obj) => count + obj.totalImagesSize.length, 0) / perPage))
        })
        
    }, [albumAias, currentPage])
    useEffect(() => {
        getEvent().then((result) => {

            setSlide(result)
        })
    }, [])
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const handleImageClick = (src) => {
        setSelectedImage(src);
    };

    const changeAlbum = (resetPageNumber, resetAlbum)=>{
        setCurrentPage(resetPageNumber)
        setAlbumAlias(resetAlbum)
    }
    function DownloadButton({ src }) {
        const download = async () => {
            try {
                const response = await fetch(src);
                const blob = await response.blob();

                // Create a temporary URL for the blob
                const url = URL.createObjectURL(blob);

                // Create a virtual link element
                const link = document.createElement('a');
                link.href = url;
                link.download = 'image.jpg'; // Set the desired file name

                // Programmatically click the link to trigger the download
                link.click();

                // Clean up the temporary URL
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading image:', error);
            }
        };

        return (
            <button onClick={download} className="bg-gray-300 hover:bg-gray-400 my-8 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                <span>Download</span>
            </button>
        );
    }
    const Modal = ({ src, onClose }) => {
        return (
            <>
                <div onClick={onClose} className="fixed z-30 inset-0  bg-gray-900 bg-opacity-60 px-4">
                    <div className="w-4/5 md:w-2/5  mt-32 h-auto mx-auto">
                        <img className="mx-auto z-50" src={src} alt="Modal Image" />
                    </div>



                </div>
                <div className='fixed flex bottom-0 right-24 z-30'>
                    <div className='  w-1/2 text-center'>
                        <button className=' bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded whitespace-nowrap w-auto my-8'
                            onClick={onClose}>Close</button>
                    </div>
                    <div className='w-1/2 text-center'>
                        <DownloadButton src={src} />
                    </div>
                </div>
            </>

        );
    };
    return (
        <>
            <MetaDecorator title={MetaDataList.home.title} description={MetaDataList.home.description} keyword={MetaDataList.home.keyword} page={MetaDataList.home.page} breadcrumb={MetaDataList.home.breadcrumb} main_description={MetaDataList.home.description} />

            {selectedImage && (
                <Modal className='' src={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <section>
                <Slider />
            </section>
            <div className='mx-2 md:mx-16 mt-8'>
                <section>
                    {slide.length > 0 && <h1 className='font-bold text-2xl'>{slide[0].title}</h1>}

                    <div className='flex gap-x-9 overflow-x-auto overflow-clip '>
                    
                        {albums.map((album, index) => (
                            <button
                                key={index}
                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-auto whitespace-nowrap   my-8'
                                onClick={() => changeAlbum(0, album.slug.current) }
                            >
                                {album.title}
                            </button>
                        ))}
                    </div>

                </section>
                <ReactPaginate
                    previousLabel={'< '}
                    nextLabel={' >'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={'inline-flex -space-x-px '}
                    activeClassName={'active'}
                    breakClassName={'px-2'}
                    pageClassName={'flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}
                    previousClassName={'my-auto mr-2'}
                    nextClassName={'my-auto ml-2 pl-2'}
                />
                <section className='min-h-96'>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mt-8'>
                        {photos.map((albums, albumsIndex) =>
                            albums['images'] &&
                            albums['images'].map((photo, index) =>
                                photo != null && (
                                    <img
                                        className="mx-auto"
                                        src={photo}
                                        key={index}
                                        onClick={() => handleImageClick(photo)}
                                    />
                                )
                            )
                        )}


                    </div>
                </section>
            </div>


        </>

    )
}


