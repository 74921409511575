import client from "../client"

export const getEvent = async (slug) => {
  const filterCondition = (slug) ? `&& slug.current == "${slug}"` : ''
  const result =  client
  .fetch(
    `
    *[_type=='event' ${filterCondition}] | order(publishedAt desc){
      title,
      slug,
      "images": images[].asset->url
    }
    `
  )
  return result;
};

export const getAlbums = async (slug, offset) => {
  const filterCondition = (slug) ? `&& slug.current == "${slug}"` : ''
  const result =  client
  .fetch(
    `
    *[_type=='gallery' ${filterCondition}] | order(publishedAt desc){
      title,
      slug,
      "images": images[${offset*12}...${offset*12+12}].asset->url,
      "totalImagesSize": images[]{"size": count(@)}
    }
    `
  )
  return result;
};
