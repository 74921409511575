import React, { useEffect, useState } from 'react'
import './Slider.css'
/* import BtnSlider from './BtnSlider' */
import { v4 as uuidv4 } from "uuid";
import leftArrow from "./icons/left-arrow.svg";
import rightArrow from "./icons/right-arrow.svg";
/* import dataSlider from './dataSlider' */
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getEvent } from "../../services"

export default function Slider() {

    const [photos, setPhotos] = useState([])
    useEffect(() => {
        getEvent().then((result) => {
            setPhotos(result)
        })
    }, [])

    let dataSlider = [

    ];

    {
        photos.map((albums, albumsIndex) => (
            albums['images'].map((photo, index) => (
                dataSlider.push({
                    id: uuidv4(),
                    title: "",
                    subTitle: "",
                    src: photo
                })
            ))
        ))
    }

    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if (slideIndex !== dataSlider.length) {
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === dataSlider.length) {
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)

        }
        else if (slideIndex === 1) {
            setSlideIndex(dataSlider.length)

        }

    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <div className="container-slider">
            {dataSlider.map((obj, index) => {
                return (
                    <div
                        key={obj.id}
                        className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <LazyLoadImage
                            src={obj.src}
                            alt="thaieasyhk清數"
                        />
                    </div>
                )
            })}
            {/*
                < button
                onClick={nextSlide}
            className="btn-slide next"
            >
            <img src={rightArrow} alt="thaieasyhk清數" />
        </button>*/}
            {/*<button
                onClick={prevSlide}
                className="btn-slide prev"
            >
                <img src={leftArrow} alt="thaieasyhk清數"/>
            </button>*/}
            {/* <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/> */}

            <div className="container-dots">
                {dataSlider.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => moveDot(index + 1)}
                        className={slideIndex === index + 1 ? "dot active" : "dot"}
                    >

                    </div>
                ))}
            </div>
            {
                dataSlider.map((obj, index) => {
                    return (
                        <div
                            key={obj.id}
                            className={slideIndex === index + 1 ? "txt-container active abosulte top-24 md:top-36 w-full" : "txt-container "}
                        >
                            <h2 className='text-theme-color-1 text-6xl md:text-7xl'>{obj.title}</h2>
                            <div className='mt-2 md:mt-8 text-4xl md:ml-96'>
                                <span className='text-theme-color-1 md:text-6xl'>{obj.subTitle}</span>
                            </div>


                        </div>
                    )
                })
            }
        </div >
    )
}
